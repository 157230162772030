import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { Accordion } from "components";
import { toLocaleStringWithCurrency } from "helpers/funcs";
import { InfoRows } from "../styled";
import { CurrenciesStore } from "stores";
import type { AvailableTradeOptions } from "helpers/types";

interface WithdrawInfoRowsProps {
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  fees: number;
  values?: { [field: string]: any };
}

const WithdrawInfoRows: FC<WithdrawInfoRowsProps> = ({
  currencyValue,
  currency,
  fees,
  values,
}) => {
  const { t } = useTranslation();
  const { currencies, prices } = CurrenciesStore;

  const mainCostValue = useMemo<number>(() => {
    const currentPrices = prices?.[`${currency}/USD`];
    const mainCost = currentPrices?.close;
    return Number(mainCost) || 0;
  }, [prices, currency]);

  return (
    <Accordion
      label={t("TOTAL")}
      subLabel={toLocaleStringWithCurrency(
        Number(currencyValue) > 0 ? Number(currencyValue) + fees : 0,
        currency || "USDT",
        currencies
      )}
    >
      <InfoRows>
        {currency && currency !== "EUR" ? (
          <ModalRow
            keyText={t("RATE")}
            value={toLocaleStringWithCurrency(mainCostValue, "USD")}
            isBold
          />
        ) : (
          <>
            {values && (
              <>
                <ModalRow
                  keyText="IBAN"
                  value={values.beneficiaryIban}
                  isBold
                />
                <ModalRow
                  keyText="Name"
                  value={values.beneficiaryName}
                  isBold
                />
                <ModalRow
                  keyText="Country"
                  value={values.beneficiaryCountryCode}
                  isBold
                />
                <ModalRow
                  keyText="City"
                  value={values.beneficiaryCity}
                  isBold
                />
                <ModalRow
                  keyText="Address"
                  value={values.beneficiaryAddress}
                  isBold
                />
                <ModalRow
                  keyText="postCode"
                  value={values.beneficiaryPostCode}
                  isBold
                />
              </>
            )}
          </>
        )}

        <ModalRow
          keyText={t("AMOUNT")}
          value={toLocaleStringWithCurrency(
            Number(currencyValue) > 0 ? currencyValue : 0,
            currency || "USDT",
            currencies
          )}
        />

        {currency !== "EUR" && (
          <ModalRow
            keyText={`${t("VALUE")} USD`}
            value={toLocaleStringWithCurrency(
              Number(currencyValue) * (Number(mainCostValue) || 0),
              "USD",
              currencies
            )}
          />
        )}

        <ModalRow
          keyText={t("FEES")}
          value={toLocaleStringWithCurrency(
            fees,
            currency || "USDT",
            currencies
          )}
        />
      </InfoRows>
    </Accordion>
  );
};

export default observer(WithdrawInfoRows);
