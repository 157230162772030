import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText, Accordion, CardImage, BuyInfoRows } from "components";
import {
  normolizeCurrenciesDecimals,
  toLocaleStringWithCurrency,
} from "helpers/funcs";
import { virtualCardDesigns } from "helpers/consts";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  InfoColumn,
} from "../styled";
import type {
  AvailableTradeOptions,
  CurrencyInterface,
  FeesInterface,
  CredictcoCardDetails,
  PricesInterface,
} from "helpers/types";
import type { CreditcoCardTransactionRes } from "api/apiTypes";

import { ReactComponent as HourGlasskIcon } from "assets/icons/hourglass-status.svg";

interface SuccessPurchaseStepProps {
  onClose: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  fees?: FeesInterface | null;
  currentFee?: string;
  cost: number;
  isFeesInUsdt?: boolean;
  prices?: PricesInterface | null;
  currencies: CurrencyInterface[] | null;
  product?: CredictcoCardDetails | null;
  balanceData: CreditcoCardTransactionRes | null;
  eurValue?: string;
  isWithdraw?: boolean;
  isTopUp?: boolean;
}

const SuccessPurchaseStep: FC<SuccessPurchaseStepProps> = ({
  onClose,
  currencyValue,
  currency,
  fees,
  currentFee,
  cost,
  isFeesInUsdt,
  prices,
  currencies,
  product,
  balanceData,
  eurValue,
  isWithdraw,
  isTopUp,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <CardImage
            src={product ? virtualCardDesigns[product.designCode].image : ""}
            alt={product ? virtualCardDesigns[product.designCode].name : ""}
          />
          <StatusIconContainer $isPending>
            <HourGlasskIcon />
          </StatusIconContainer>
        </StatusDecorative>

        <InfoColumn>
          <AppText
            fontSize={20}
            fontWeight={600}
            color={colors.gray_600}
            lineHeight={30}
          >
            {t("SUCCESS")}
          </AppText>
          <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
            {t(
              isWithdraw ? "WITHDRAW_WAS_SUCCESSFUL" : "TOP_UP_WAS_SUCCESSFUL"
            )}
          </AppText>
          {balanceData && (
            <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
              {t("NOW_YOUR_BALANCE")}{" "}
              <b>
                {toLocaleStringWithCurrency(
                  product?.balance || 0,
                  product?.currencyName || "EUR"
                )}
              </b>
            </AppText>
          )}

          <Accordion
            label={t("YOU_RECEIVED")}
            subLabel={`${normolizeCurrenciesDecimals(
              isWithdraw ? currencyValue : eurValue || 0,
              isWithdraw ? currency || "USDT" : product?.currencyName || "EUR",
              currencies,
              "forView"
            )} ${
              isWithdraw ? currency || "USDT" : product?.currencyName || "EUR"
            }`}
          >
            {product && (
              <BuyInfoRows
                cost={cost}
                isFeesInUsdt={isFeesInUsdt}
                prices={prices}
                currencies={currencies}
                currency={currency}
                productCurrency={product.currencyName}
                currencyValue={currencyValue}
                eurValue={eurValue}
                currentFee={currentFee}
                isSuccess
                userCard
                isWithdraw={isWithdraw}
                isTopUp={isTopUp}
              />
            )}
          </Accordion>
          <ModalButton onClick={onClose}>{t("CONTINUE")}</ModalButton>
        </InfoColumn>
      </StatusContent>
    </Fade>
  );
};

export default SuccessPurchaseStep;
