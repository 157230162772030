import { FC, useState, useCallback, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import {
  topUpCreditcoCard,
  withdrawCreditcoCard,
  getCreditcoCardDetails,
  getUsersCreditcoCardsList,
} from "api/creditco";
import ConfirmPurchaseStep from "./ConfirmPurchaseStep";
import SuccessPurchaseStep from "./SuccessPurchaseStep";
import { GlobalStore } from "stores";
import type {
  CreditcoCardTransactionReq,
  CreditcoCardTransactionRes,
} from "api/apiTypes";
import type {
  AvailableTradeOptions,
  CurrencyInterface,
  FeesInterface,
  ModalStepsType,
  CredictcoCardDetails,
  PricesInterface,
} from "helpers/types";

interface CryptoCardTransactionsModalProps {
  isOpen: boolean;
  product?: CredictcoCardDetails | null;
  setProduct: Dispatch<SetStateAction<CredictcoCardDetails | null>>;
  onClose: () => void;
  onReset?: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  fees?: FeesInterface | null;
  currentFee?: string;
  cost: number;
  isFeesInUsdt?: boolean;
  prices?: PricesInterface | null;
  currencies: CurrencyInterface[] | null;
  onBack: () => void;
  isWithdraw?: boolean;
  isTopUp?: boolean;
  eurValue: string;
  isMaxValue?: boolean;
}

const CryptoCardTransactionsModal: FC<CryptoCardTransactionsModalProps> = ({
  isOpen,
  product,
  setProduct,
  onClose,
  onReset,
  currencyValue,
  currency,
  fees,
  currentFee,
  cost,
  isFeesInUsdt,
  prices,
  currencies,
  onBack,
  isWithdraw,
  isTopUp,
  eurValue,
  isMaxValue,
}) => {
  const { cardId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState<ModalStepsType>("confirm");
  const [balanceData, setBalanceData] =
    useState<CreditcoCardTransactionRes | null>(null);

  const onSubmit = () => {
    if (!currency || !product || !currencyValue) {
      return;
    }

    setIsLoading(true);

    const reqData: CreditcoCardTransactionReq = {
      amountInEuro: String(eurValue),
      walletCurrency: currency,
    };

    if (!isWithdraw && eurValue) {
      if (isMaxValue) {
        reqData.isMaxVolume = true;
      }

      topUpCreditcoCard(product.id, reqData)
        .then(({ data }) => {
          setBalanceData(data);
          getUsersCreditcoCardsList();
          getCreditcoCardDetails(Number(cardId))
            .then(({ data }) => {
              setProduct(data);
            })
            .catch((err) => {
              if (err?.response?.data?.message) {
                GlobalStore.setError(err.response.data.message);
              }
            });
          setStep("success");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
      return;
    }

    withdrawCreditcoCard(product.id, reqData)
      .then(({ data }) => {
        setBalanceData(data);
        getUsersCreditcoCardsList();
        getCreditcoCardDetails(Number(cardId))
          .then(({ data }) => {
            setProduct(data);
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              GlobalStore.setError(err.response.data.message);
            }
          });
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const closeModal = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (step === "confirm") {
      onBack();
      return;
    }

    onClose();
  }, [step, isLoading, onClose, onBack]);

  const resetState = useCallback(() => {
    if (!!onReset && step === "success") {
      onReset();
    }
    setBalanceData(null);
    setStep("confirm");
  }, [onReset, step]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={closeModal}
      title="Purchase card"
    >
      {isLoading && <Preloader isStatic />}

      {step === "confirm" && (
        <ConfirmPurchaseStep
          currency={currency}
          currencies={currencies}
          cost={cost}
          isFeesInUsdt={isFeesInUsdt}
          prices={prices}
          currencyValue={currencyValue}
          fees={fees}
          currentFee={currentFee}
          onClose={closeModal}
          onSubmit={onSubmit}
          product={product}
          eurValue={eurValue}
          isWithdraw={isWithdraw}
          isTopUp={isTopUp}
        />
      )}

      {step === "success" && (
        <SuccessPurchaseStep
          currency={currency}
          currencies={currencies}
          cost={cost}
          isFeesInUsdt={isFeesInUsdt}
          prices={prices}
          currencyValue={currencyValue}
          fees={fees}
          currentFee={currentFee}
          onClose={closeModal}
          product={product}
          balanceData={balanceData}
          eurValue={eurValue}
          isWithdraw={isWithdraw}
          isTopUp={isTopUp}
        />
      )}
    </BasicModal>
  );
};

export default observer(CryptoCardTransactionsModal);
