import { FC, useState, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import BasicModal from "../BasicModal";
import { Fade } from "@mui/material";
import {
  CloseModalButton,
  CurrencyButton,
  MainButton,
} from "components/Buttons";
import { ReadInfo, Select } from "components/Inputs";
import { SelectItemProps } from "components/Inputs/types";
import { AppText, Preloader, VerificationWarning } from "components";
import {
  ModalContent,
  ModalHeading,
  MainContainer,
  TransactionGroup,
  InnerContent,
  QrContainer,
  QrZone,
  WarningsContainer,
  PaddingContainer,
} from "../styled";
import { colors } from "helpers/consts";
import { CurrenciesStore, WalletStore, UserStore, GlobalStore } from "stores";
import { createWallet, createWalletAddress } from "api/wallet";
import { getFoxpayBankAccDetails } from "api/foxpay";
import { socket } from "api/socket";
import PaymentSelect from "../PaymentSelect";
import { ModalWarningInfo } from "components/Modals";
import DepositInfoRows from "./DepositInfoRows";
import type {
  AvailableTradeOptions,
  WalletsListInterface,
  WalletsAddressInterface,
  CurrencyInterface,
  // Timer,
} from "helpers/types";
import type { FoxpayGetBankAccRes, CreateNewAddressRes } from "api/apiTypes";

// let timeOut: Timer;

// export const bankVariant: CurrencyInterface = {
//   code: "EUR",
//   depositEnabled: true,
//   depositFee: 0,
//   networkName: "EUR",
//   networkCode: "EUR",
//   maxDeposit: 1000000,
//   maxWithdraw: 1000000,
//   minDeposit: 0,
//   minWithdraw: 0,
//   name: "EUR",
//   precision: 8,
//   sign: "EUR",
//   text: "EUR",
//   coin: "EUR",
//   withdrawEnabled: true,
//   withdrawFee: 10,
// };

interface DepositModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialVariant: AvailableTradeOptions;
  isFiat?: boolean;
}

const DepositModal: FC<DepositModalProps> = ({
  isOpen,
  onClose,
  initialVariant,
  isFiat = false,
}) => {
  const { t } = useTranslation();
  const { currencies, prices } = CurrenciesStore;
  const { walletsList, addWalletAddress } = WalletStore;
  const {
    user: { level },
  } = UserStore;
  const [chosenNetwork, setChosenNetwork] = useState<string>("");
  // const [tooltipiIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewAddressLoading, setIsNewAddressLoading] =
    useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [addNewAddressError, setAddNewAddressError] = useState<boolean>(false);
  const [bankAccountDetails, setBankAccountDetails] =
    useState<FoxpayGetBankAccRes | null>(null);
  const [paymentType, setPaymentType] = useState<CurrencyInterface | undefined>(
    undefined
  );

  const closeSelect = () => setIsSelectOpen(false);
  const openSelect = () => setIsSelectOpen(true);

  // const closeTooltip = () => {
  //   setTooltipIsOpen(false);
  // };

  const onSelectNetwork = useCallback(
    (networkValue: string) => {
      if (
        currencies &&
        paymentType &&
        networkValue !== paymentType?.networkCode
      ) {
        setPaymentType(
          currencies.find(
            ({ networkCode, name }) =>
              paymentType.name === name && networkCode === networkValue
          )
        );
      }
      setAddNewAddressError(false);
      setChosenNetwork(networkValue || "");
    },
    [currencies, paymentType]
  );

  // const handleCopy = () => {
  //   if (paymentType?.code !== "EUR" && !walletAddress) {
  //     return;
  //   }
  //   if (paymentType?.code !== "EUR" && walletAddress) {
  //     navigator.clipboard.writeText(walletAddress);
  //   }
  //   if (paymentType?.code === "EUR") {
  //     navigator.clipboard.writeText(testIban);
  //   }
  //   navigator.clipboard.writeText(testDeposit);
  //   setTooltipIsOpen(true);
  // };
  const onPaymentChange = (option: CurrencyInterface) => {
    onSelectNetwork("");
    setBankAccountDetails(null);
    setPaymentType(option);
  };

  const currentWallet = useMemo<WalletsListInterface | undefined>(() => {
    return walletsList?.find(
      ({ currencyName }) => currencyName === paymentType?.name
    );
  }, [walletsList, paymentType]);

  const walletAddress = useMemo<string | undefined>(() => {
    if (paymentType?.name && currentWallet && chosenNetwork) {
      return currentWallet.addresses.find(
        ({ networkCode }) => networkCode === chosenNetwork
      )?.address;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsList, paymentType, chosenNetwork, currentWallet]);

  const walletMemo = useMemo<string | undefined | null>(() => {
    if (
      paymentType?.name &&
      currentWallet &&
      chosenNetwork &&
      paymentType?.useMemo
    ) {
      return currentWallet.addresses.find(
        ({ networkCode }) => networkCode === chosenNetwork
      )?.memo;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsList, paymentType, chosenNetwork, currentWallet]);

  useEffect(() => {
    const createNewWallet = async () => {
      setIsLoading(true);
      await createWallet(paymentType!.name);
      setIsLoading(false);
    };
    if (paymentType?.name && !currentWallet) {
      createNewWallet();
    }
  }, [paymentType, currentWallet]);

  useEffect(() => {
    if (level === "SENIOR" && paymentType?.name === "EUR") {
      setIsLoading(true);
      getFoxpayBankAccDetails(paymentType.name)
        .then(({ data }) => setBankAccountDetails(data))
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [paymentType, level]);

  useEffect(() => {
    if (
      isOpen &&
      currentWallet &&
      !chosenNetwork &&
      paymentType?.networkCode &&
      currencies?.filter((el) => el.name === paymentType?.name)?.length === 1
    ) {
      onSelectNetwork(paymentType?.networkCode);
    }
  }, [
    paymentType,
    currentWallet,
    chosenNetwork,
    currencies,
    isOpen,
    onSelectNetwork,
  ]);

  const addNewWalletAddress = useCallback(
    (data: CreateNewAddressRes) => {
      if (data.status === "REQUESTED") {
        return;
      }
      if (data.address && data.status === "SUCCESS") {
        const newWalletAddress: WalletsAddressInterface = {
          address: data.address!,
          currencyName: data.currencyName,
          memo: data.memo,
          networkCode: data.networkCode,
          networkName: data.networkName,
        };
        addWalletAddress(newWalletAddress, data.currencyName);
      }
      if (data.status === "ERROR") {
        setAddNewAddressError(true);
        GlobalStore.setError(
          "Sorry, unknown error occurred and address for this currency haven't created"
        );
      }
      socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
      setIsNewAddressLoading(false);
    },
    [addWalletAddress]
  );

  const createNewWalletAddress = useCallback(async () => {
    if (paymentType?.name && currentWallet && chosenNetwork && !walletAddress) {
      setAddNewAddressError(false);
      setIsNewAddressLoading(true);
      socket.on("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);

      await createWalletAddress(paymentType?.networkCode!, paymentType?.name!)
        .then(({ data }) => addNewWalletAddress(data))
        .catch((err) => {
          socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
          setIsNewAddressLoading(false);
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        });
    }
  }, [
    chosenNetwork,
    paymentType?.name,
    currentWallet,
    walletAddress,
    paymentType?.networkCode,
    addNewWalletAddress,
  ]);

  useEffect(() => {
    createNewWalletAddress();
  }, [createNewWalletAddress]);

  useEffect(() => {
    return () => {
      socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
    };
  }, [addNewWalletAddress]);

  // useEffect(() => {
  //   if (tooltipiIsOpen) {
  //     timeOut = setTimeout(() => closeTooltip(), 1500);
  //   }

  //   return () => clearTimeout(timeOut);
  // }, [tooltipiIsOpen]);

  useEffect(() => {
    if (isOpen && currencies) {
      // if (isFiat) {
      //   setPaymentType(bankVariant);
      //   return;
      // }
      currencies &&
        setPaymentType(currencies.find(({ name }) => name === initialVariant)!);
    }
  }, [initialVariant, isFiat, currencies, isOpen]);

  const currenciesOptions = useMemo<CurrencyInterface[] | null>(() => {
    if (currencies) {
      return currencies.reduce(
        (acc: CurrencyInterface[], el: CurrencyInterface) => {
          if (el.depositEnabled && !acc.find(({ name }) => name === el.name)) {
            acc.push(el);
          }
          return acc;
        },
        []
      );
    }
    return null;
  }, [currencies]);

  const networkOptions = useMemo<Array<SelectItemProps> | undefined>(() => {
    if (currencies && paymentType) {
      return currencies
        .filter(({ name }) => name === paymentType.name)
        .map((el) => ({
          value: el.networkCode,
          label: el.networkName,
        }));
    }
    return undefined;
  }, [currencies, paymentType]);

  const resetState = useCallback(() => {
    setPaymentType(undefined);
    onSelectNetwork("");
    setBankAccountDetails(null);
    setAddNewAddressError(false);
    setIsLoading(false);
    setIsNewAddressLoading(false);
  }, [onSelectNetwork]);

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      onExited={resetState}
      title={t("DEPOSIT")}
    >
      <Fade in timeout={500}>
        <ModalContent>
          {(isLoading || isNewAddressLoading) && (
            <Preloader
              isStatic
              text={
                isNewAddressLoading
                  ? t("NEW_WALLET_ADDRESS_CREATING")
                  : undefined
              }
            />
          )}
          <CloseModalButton onClose={onClose} />
          <ModalHeading>{t("DEPOSIT")}</ModalHeading>
          <InnerContent>
            <MainContainer>
              <TransactionGroup>
                <AppText color={colors.gray_500}>
                  {t("CHOOSE_METHOD_ASSET")}
                </AppText>
                <CurrencyButton
                  value={paymentType?.name}
                  icon={paymentType?.name}
                  onClick={openSelect}
                />
              </TransactionGroup>
              {networkOptions && paymentType?.name !== "EUR" && (
                <TransactionGroup>
                  <AppText color={colors.gray_500}>
                    {t("SELECT_NETWORK")}
                  </AppText>

                  <Select
                    variants={networkOptions}
                    value={chosenNetwork}
                    setValue={onSelectNetwork}
                  />
                </TransactionGroup>
              )}
            </MainContainer>

            {addNewAddressError && (
              <ModalWarningInfo
                text={t("ERROR_CREATING_ADDRESS")}
                fontSize={16}
                button={
                  <MainButton
                    variant="contained"
                    onClick={createNewWalletAddress}
                  >
                    {t("TRY_AGAIN")}
                  </MainButton>
                }
              />
            )}

            {paymentType?.name === "EUR" && level !== "SENIOR" ? (
              <VerificationWarning
                text={`${paymentType.name} ${t("DEPOSIT_VERIFY_REQUIRED")}`}
              />
            ) : (
              <>
                <WarningsContainer>
                  <ModalWarningInfo
                    text={
                      paymentType?.name !== "EUR"
                        ? `${t("CHECK_DEPOSIT_ADDRESS_1")} ${
                            paymentType?.name || ""
                          }${
                            chosenNetwork && paymentType?.networkName
                              ? ` ${paymentType?.networkName}`
                              : ""
                          }  ${t("CHECK_DEPOSIT_ADDRESS_2")}`
                        : t("SENDING_FUNDS_CASH_INFO")
                    }
                    subText={
                      paymentType?.name !== "EUR"
                        ? t("SENDING_FUNDS_INFO")
                        : t("SENDING_CASH_FUNDS_INFO")
                    }
                  />

                  <ModalWarningInfo text={t("PROCEED_INFO")} />
                </WarningsContainer>

                <MainContainer $padding="1.6rem 1rem">
                  <TransactionGroup>
                    <AppText color={colors.gray_500}>
                      {t("DEPOSIT_ADDRESS")}
                    </AppText>
                    {paymentType?.name !== "EUR" ? (
                      <>
                        {!paymentType?.useMemo && (
                          <QrContainer>
                            <QrZone $isBlured={!walletAddress}>
                              <QRCode
                                value={walletAddress || "placeholderQR"}
                                size={144}
                              />
                            </QrZone>
                          </QrContainer>
                        )}
                        {paymentType?.useMemo && walletMemo ? (
                          <>
                            <AppText
                              color={colors.gray_500}
                              style={{ marginTop: "2rem" }}
                            >
                              Memo
                            </AppText>
                            <ReadInfo text={walletMemo} withCopy />
                          </>
                        ) : null}
                        <AppText
                          color={colors.gray_500}
                          style={{ marginTop: "1rem" }}
                        >
                          Address
                        </AppText>
                        <ReadInfo
                          text={
                            walletAddress ||
                            "notRealWalletAddressJustSomeExample"
                          }
                          isBlured={!walletAddress}
                          withCopy
                        />
                      </>
                    ) : (
                      <>
                        <PaddingContainer>
                          <ReadInfo
                            text={
                              isLoading
                                ? "PLACEHOLDERIBAN"
                                : bankAccountDetails?.iban || "-"
                            }
                            withCopy
                            info="IBAN"
                            isBlured={isLoading}
                          />
                        </PaddingContainer>

                        <PaddingContainer>
                          <ReadInfo
                            text={
                              isLoading
                                ? "PLACEHOLDERBIC"
                                : bankAccountDetails?.bic || "-"
                            }
                            withCopy
                            info="BIC/SWIFT"
                            isBlured={isLoading}
                          />
                        </PaddingContainer>

                        <PaddingContainer>
                          <ReadInfo
                            text={
                              isLoading
                                ? "Placeholder Address"
                                : `${bankAccountDetails?.country + ", " || ""}${
                                    bankAccountDetails?.address || ""
                                  }`
                            }
                            withCopy
                            allowWrap
                            info={t("ADDRESS")}
                            isBlured={isLoading}
                          />
                        </PaddingContainer>

                        <PaddingContainer>
                          <ReadInfo
                            text={
                              isLoading
                                ? "Bank Name"
                                : bankAccountDetails?.bankName || "-"
                            }
                            withCopy
                            info={t("BANK")}
                            isBlured={isLoading}
                          />
                        </PaddingContainer>

                        <PaddingContainer>
                          <ReadInfo
                            text={
                              isLoading
                                ? "Bank Address"
                                : bankAccountDetails?.bankAddress || "-"
                            }
                            withCopy
                            info={t("BANK_ADDRESS")}
                            isBlured={isLoading}
                          />
                        </PaddingContainer>

                        <ModalWarningInfo
                          component={
                            <ReadInfo
                              text={
                                isLoading
                                  ? "PLACEHOLDERCODE"
                                  : bankAccountDetails?.code || "-"
                              }
                              withCopy
                              info={t("DETAILS")}
                              isBlured={isLoading}
                            />
                          }
                          padding="0.8rem 0.4rem"
                          text={t("DEPOSIT_WARNING")}
                        />
                      </>
                    )}
                  </TransactionGroup>
                </MainContainer>

                <DepositInfoRows currency={paymentType} prices={prices} />
              </>
            )}

            {/* <SemiContainer>
                  <Checkbox
                    checked={isWarnConfirmed}
                    onClick={() => setIsWarnConfirmed(!isWarnConfirmed)}
                    label={t("CONFIRM_PROCEED_INFO")}
                    variant="warn"
                  />

                  <Tooltip
                    onClose={closeTooltip}
                    open={tooltipiIsOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={t("COPIED")}
                  >
                    <ModalButton
                      onClick={handleCopy}
                      disabled={!isWarnConfirmed}
                    >
                      {paymentType?.name !== "EUR"
                        ? t("COPY_DEPOSIT_ADDRESS")
                        : `${t("COPY")} IBAN`}
                    </ModalButton>
                  </Tooltip>
                </SemiContainer> */}
          </InnerContent>
          <PaymentSelect
            onClose={closeSelect}
            isOpen={isSelectOpen}
            selected={paymentType}
            options={currenciesOptions}
            setValue={onPaymentChange}
            isDeposit
          />
        </ModalContent>
      </Fade>
    </BasicModal>
  );
};

export default observer(DepositModal);
