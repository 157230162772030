import { FC, useState, useMemo, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import {
  ModalButton,
  CloseModalButton,
  BackModalButton,
  CurrencyButton,
  SquereButton,
} from "components/Buttons";
import { Select } from "components/Inputs";
import { ModalRow } from "components/Rows";
import {
  AddDeliveryAddressModal,
  ModalWarningInfo,
  CurrencySelectModal,
} from "components/Modals";
import { SelectItemProps } from "components/Inputs/types";
import { Accordion, AppText, CardImage, Tooltip } from "components";
import { virtualCardDesigns, colors } from "helpers/consts";
import {
  toLocaleStringWithCurrency,
  normolizeCurrenciesDecimals,
} from "helpers/funcs";
import {
  ModalHeading,
  Form,
  InputContainer,
  StatusDecorative,
  InputLineContainer,
} from "../styled";
import type { CostsInterface } from "./";
import type {
  CredictcoCardDetails,
  DeliveryAddress,
  CurrencyInterface,
  AvailableTradeOptions,
  WalletsListInterface,
} from "helpers/types";
import {
  InfoLine,
  ModalError,
  ModalErrorStatus,
} from "components/Modals/styled";

import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

interface MainPurchasePlasticStepProps {
  isSubmitting: boolean;
  values: { [field: string]: any };
  onClose: () => void;
  errors: { [field: string]: string };
  handleBlur: (e: any) => void;
  handleChange: (e: any) => void;
  handleSubmit: () => void;
  touched: { [field: string]: boolean };
  addressOptions: SelectItemProps[];
  deliveryMethodsOptions: SelectItemProps[];
  cardInfo: CredictcoCardDetails | null;
  setNewDeliveryAddresses: (address: DeliveryAddress) => void;
  setNewAddress: (id: number) => void;
  currencies: CurrencyInterface[] | null;
  costInfos: CostsInterface;
  selectedCurrency: AvailableTradeOptions | null;
  walletsList: WalletsListInterface[] | null;
  setSelectedCurrecy: Dispatch<SetStateAction<AvailableTradeOptions | null>>;
  hasError: string | null;
  cost: number;
  currencyVariants: AvailableTradeOptions[];
}

const MainPurchasePlasticStep: FC<MainPurchasePlasticStepProps> = ({
  onClose,
  isSubmitting,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  addressOptions,
  deliveryMethodsOptions,
  cardInfo,
  setNewDeliveryAddresses,
  setNewAddress,
  currencies,
  costInfos,
  selectedCurrency,
  walletsList,
  setSelectedCurrecy,
  hasError,
  cost,
  currencyVariants,
}) => {
  const { t } = useTranslation();
  const [isAddNewAddressOpen, setIsAddNewAddressOpen] =
    useState<boolean>(false);
  const [isCurrencySelectOpen, setIsCurrencySelectOpen] =
    useState<boolean>(false);

  const currencyBalance = useMemo<number>(() => {
    const leftBalance = walletsList?.find(
      ({ currencyName }) => selectedCurrency === currencyName
    )?.balance;
    return !selectedCurrency || !leftBalance
      ? 0
      : Number(
          normolizeCurrenciesDecimals(leftBalance, selectedCurrency, currencies)
        );
  }, [walletsList, selectedCurrency, currencies]);

  return (
    <>
      <Fade in timeout={500}>
        <Form onSubmit={handleSubmit}>
          <ModalHeading $centered>{t("ORDER_PLASTIC_CARD")}</ModalHeading>
          <CloseModalButton onClose={onClose} />
          <BackModalButton onBack={onClose} />

          <StatusDecorative $noIcon>
            <CardImage
              src={
                cardInfo ? virtualCardDesigns[cardInfo?.designCode]?.image : ""
              }
              alt={cardInfo?.cardTemplate.cardBrand || "card"}
            />
          </StatusDecorative>

          <InputContainer>
            <AppText>{t("DELIVERY_ADDRESS")}</AppText>
            <InputLineContainer>
              <Select
                name="deliveryAddressId"
                placeholder=""
                variants={addressOptions}
                value={values.deliveryAddressId}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={addressOptions.length < 1}
                error={
                  touched.deliveryAddressId && Boolean(errors.deliveryAddressId)
                }
                helperText={
                  touched.deliveryAddressId && errors.deliveryAddressId
                }
              />
              <Tooltip title={t("ADD_NEW_DELIVERY_ADDRESS")}>
                <SquereButton
                  $view="forInput"
                  onClick={() => setIsAddNewAddressOpen(true)}
                >
                  <EditIcon />
                </SquereButton>
              </Tooltip>
            </InputLineContainer>
          </InputContainer>

          <ModalWarningInfo text={t("DELIVERY_RESTRICTIONS")} />

          <InputContainer style={{ marginTop: "1.6rem" }}>
            <AppText color={colors.gray_400} fontWeight={400}>
              {t("CHOOSE_METHOD_ASSET")}
            </AppText>

            <CurrencyButton
              onClick={() => setIsCurrencySelectOpen(true)}
              value={selectedCurrency || undefined}
              icon={selectedCurrency || undefined}
            />
            <InfoLine>
              <WalletIcon />
              <AppText color={colors.gray_600} fontWeight={400} fontSize={12}>
                {t("BALANCE")} {currencyBalance} {selectedCurrency}
              </AppText>
            </InfoLine>
          </InputContainer>

          <InputContainer>
            <AppText>{t("DELIVERY_METHOD")}</AppText>
            <Select
              name="deliveryMethodId"
              placeholder=""
              variants={deliveryMethodsOptions}
              value={values.deliveryMethodId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.deliveryMethodId && Boolean(errors.deliveryMethodId)
              }
              helperText={touched.deliveryMethodId && errors.deliveryMethodId}
            />
          </InputContainer>

          <Accordion
            label={t("TOTAL_PRICE")}
            subLabel={`${normolizeCurrenciesDecimals(
              costInfos.cardCost + costInfos.deliveryMethodCost,
              selectedCurrency || "USDT",
              currencies,
              "forView"
            )} ${selectedCurrency || "USDT"}`}
          >
            <ModalRow
              keyText={t("RATE")}
              value={`≈ ${toLocaleStringWithCurrency(
                cost,
                cardInfo?.currencyName || "EUR",
                currencies
              )}`}
              isBold
            />
            <ModalRow
              keyText={t("CARD_COST")}
              value={toLocaleStringWithCurrency(
                costInfos.cardCost,
                selectedCurrency || "USDT"
              )}
            />
            <ModalRow
              keyText={t("DELIVERY_COST")}
              value={toLocaleStringWithCurrency(
                costInfos.deliveryMethodCost,
                selectedCurrency || "USDT"
              )}
            />
          </Accordion>

          {hasError && (
            <ModalError style={{ marginTop: "1rem" }}>
              <ModalErrorStatus>
                <AppText fontSize={12} color={colors.error_700}>
                  {t("WARNING")}
                </AppText>
              </ModalErrorStatus>
              <AppText fontSize={12} color={colors.error_700}>
                {t(hasError)}
              </AppText>
            </ModalError>
          )}

          <ModalButton
            variant="contained"
            fullWidth
            type="submit"
            style={{ marginTop: "1rem" }}
            disabled={isSubmitting || !!hasError}
          >
            {t("TO_ORDER")}
          </ModalButton>
        </Form>
      </Fade>

      <AddDeliveryAddressModal
        isOpen={isAddNewAddressOpen}
        onClose={() => setIsAddNewAddressOpen(false)}
        setNewDeliveryAddresses={setNewDeliveryAddresses}
        setNewAddress={setNewAddress}
      />

      <CurrencySelectModal
        isOpen={isCurrencySelectOpen}
        onClose={() => setIsCurrencySelectOpen(false)}
        options={currencyVariants}
        selected={selectedCurrency || null}
        setValue={setSelectedCurrecy}
      />
    </>
  );
};

export default MainPurchasePlasticStep;
