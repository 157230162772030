import {
  FC,
  useCallback,
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BasicModal from "../BasicModal";
import { Preloader } from "components";
import {
  convertCardToPhysical,
  getDeliveryAddresses,
  getUsersCreditcoCardsList,
} from "api/creditco";
import { useFormik } from "formik";
import { WalletStore, CurrenciesStore, GlobalStore } from "stores";
import { SelectItemProps } from "components/Inputs/types";
import MainPurchasePlasticStep from "./MainPurchasePlasticStep";
import SuccessPurchasePlasticStep from "./SuccessPurchasePlasticStep";
import { EeaCountries } from "helpers/consts";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import type {
  ModalStepsType,
  DeliveryAddress,
  CredictcoCardDetails,
  AvailableTradeOptions,
  WalletsListInterface,
} from "helpers/types";
import type { ConvertCardToPhysicalReq } from "api/apiTypes";

const exceedAmount = "ERROR_NO_AMOUNT";
const unavailablePair = "CURRENTLY_UNAVAILABLE_CURRENCY";

interface InitialErrors {
  deliveryAddressId?: string;
  deliveryMethodId?: string;
}
export interface CostsInterface {
  cardCost: number;
  deliveryMethodCost: number;
}

interface PurchasePlasticCryptoCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  cardInfo: CredictcoCardDetails | null;
  setCardInfo: Dispatch<SetStateAction<CredictcoCardDetails | null>>;
}

const PurchasePlasticCryptoCardModal: FC<
  PurchasePlasticCryptoCardModalProps
> = ({ isOpen, onClose, cardInfo, setCardInfo }) => {
  const { t } = useTranslation();
  const { walletsList } = WalletStore;
  const { currencies, prices } = CurrenciesStore;
  const [selectedCurrency, setSelectedCurrecy] =
    useState<AvailableTradeOptions | null>(null);
  const [hasError, setHasError] = useState<string | null>("");

  const [step, setStep] = useState<ModalStepsType>("main");
  const [deliveryAddresses, setDeliveryAddresses] = useState<
    DeliveryAddress[] | null
  >(null);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      deliveryAddressId: "",
      deliveryMethodId: "",
    },

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const changePassData: ConvertCardToPhysicalReq = {
        deliveryAddressId: Number(values.deliveryAddressId),
        deliveryMethodId: Number(values.deliveryMethodId),
        userCardId: cardInfo!.id,
        currencyName: selectedCurrency!,
      };

      convertCardToPhysical(changePassData)
        .then(() => {
          setCardInfo({ ...cardInfo!, cardType: "PHYSICAL" });
          getUsersCreditcoCardsList();
          setStep("success");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setSubmitting(false));
    },

    validate: (values) => {
      const errors: InitialErrors = {};
      if (!values.deliveryAddressId) {
        errors.deliveryAddressId = "Required field";
      }
      if (!values.deliveryMethodId) {
        errors.deliveryMethodId = "Required field";
      }
      return errors;
    },
  });

  const mainCostValue = useMemo<number>(() => {
    if (!prices || !cardInfo) {
      return 0;
    }
    if (cardInfo.cardTemplate.cardMaterial.currencyName === selectedCurrency) {
      return 1;
    }
    let mainCost: string | number =
      prices[
        `${selectedCurrency}/${cardInfo.cardTemplate.cardMaterial.currencyName}`
      ]?.close;

    if (!mainCost) {
      const usdCoinCost = prices[`${selectedCurrency}/USD`]?.close;
      const usdtValue = prices["USDT/USD"]?.close;

      if (!usdtValue || !usdCoinCost) {
        return 0;
      }

      mainCost = Number(usdCoinCost) / Number(usdtValue);
    }

    return Number(mainCost) || 0;
  }, [prices, cardInfo, selectedCurrency]);

  const costInfos = useMemo<CostsInterface>(() => {
    if (!cardInfo || !values.deliveryMethodId || !mainCostValue) {
      return {
        cardCost: 0,
        deliveryMethodCost: 0,
      };
    }
    const deliveryInfo = cardInfo.cardTemplate.cardDeliveryMethods.find(
      ({ id }) => id === Number(values.deliveryMethodId)
    );
    const cardCostInfo = cardInfo.cardTemplate.cardMaterial;

    return {
      cardCost: Number(cardCostInfo.cost) / Number(mainCostValue),
      deliveryMethodCost: deliveryInfo
        ? Number(deliveryInfo.cost) / Number(mainCostValue)
        : 0,
    };
  }, [cardInfo, values.deliveryMethodId, mainCostValue]);

  const addressOptions = useMemo<Array<SelectItemProps>>(() => {
    if (!deliveryAddresses) {
      return [];
    }
    return deliveryAddresses
      .filter(({ country }) => EeaCountries.includes(country))
      .map(({ city, address, postCode, id }) => ({
        value: id,
        label: `${city}, ${address}, ${postCode}`,
      }));
  }, [deliveryAddresses]);

  const deliveryMethodsOptions = useMemo<Array<SelectItemProps>>(() => {
    if (!cardInfo?.cardTemplate.cardDeliveryMethods) {
      return [];
    }
    return cardInfo.cardTemplate.cardDeliveryMethods.map(
      ({ type, id, cost }) => ({
        value: id,
        label: `${t(type)} (${normolizeCurrenciesDecimals(
          Number(cost) / mainCostValue,
          selectedCurrency || "USDT",
          currencies
        )} ${selectedCurrency})`,
      })
    );
  }, [cardInfo, t, mainCostValue, selectedCurrency, currencies]);

  useEffect(() => {
    if (!values.deliveryAddressId && addressOptions.length > 0) {
      setFieldValue("deliveryAddressId", addressOptions[0].value);
    }
    if (!values.deliveryMethodId && deliveryMethodsOptions.length > 0) {
      setFieldValue("deliveryMethodId", deliveryMethodsOptions[0].value);
    }
  }, [
    addressOptions,
    values.deliveryAddressId,
    values.deliveryMethodId,
    deliveryMethodsOptions,
    setFieldValue,
  ]);

  const resetState = useCallback(() => {
    resetForm();
    setStep("main");
  }, [resetForm]);

  const setNewAddress = useCallback(
    (id: number) => {
      setFieldValue("deliveryAddressId", id);
    },
    [setFieldValue]
  );

  const setNewDeliveryAddresses = useCallback(
    (address: DeliveryAddress) =>
      setDeliveryAddresses(
        deliveryAddresses ? [...deliveryAddresses, address] : [address]
      ),
    [deliveryAddresses]
  );

  const currencyVariants = useMemo<AvailableTradeOptions[]>(() => {
    if (!walletsList) {
      return [];
    }
    const data = [...walletsList]
      .sort((a, b) => Number(b.balance) - Number(a.balance))
      .map(({ currencyName }) => currencyName);
    return data;
  }, [walletsList]);

  useEffect(() => {
    if (!cardInfo) {
      return;
    }
    if (!selectedCurrency && cardInfo.cardTemplate.isRwa) {
      setSelectedCurrecy(cardInfo.cardTemplate.currencyName);
      return;
    }
    if (!selectedCurrency && currencyVariants.length > 0) {
      if (cardInfo.cardTemplate.currencyName === currencyVariants[0]) {
        setSelectedCurrecy(currencyVariants[1]);
        return;
      }
      if (!walletsList || !prices) {
        setSelectedCurrecy(currencyVariants[0]);
        return;
      }
      const mainUsersCoin = [...walletsList!]
        .filter(({ currencyName }) => currencyName !== "STBU")
        .reduce((acc: WalletsListInterface | null, coin) => {
          const coinValue =
            Number(coin.balance) *
              Number(prices![`${coin.currencyName}/USD`]?.close) || 0;

          const currentCoinValue =
            Number(acc?.balance) *
              Number(prices![`${acc?.currencyName}/USD`]?.close) || 0;

          if (coinValue > currentCoinValue) {
            acc = coin;
          }
          return acc;
        }, null);

      if (mainUsersCoin) {
        setSelectedCurrecy(mainUsersCoin.currencyName);
        return;
      }
      setSelectedCurrecy(currencyVariants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency, currencyVariants, cardInfo, walletsList]);

  const fullCurrencyBalance = useMemo<number>(() => {
    const balance = walletsList?.find(
      ({ currencyName }) => selectedCurrency === currencyName
    )?.balance;

    return !selectedCurrency || !balance ? 0 : Number(balance);
  }, [walletsList, selectedCurrency]);

  const checkIfError = (value: number) => {
    if (hasError) {
      setHasError(null);
    }

    if (cardInfo && prices && selectedCurrency && mainCostValue === 0) {
      setHasError(unavailablePair);
      return;
    }

    if (!value) {
      return;
    }

    if ((Number(value) || 0) / Number(mainCostValue) > fullCurrencyBalance) {
      setHasError(exceedAmount);
      return;
    }
  };

  useEffect(() => {
    if (costInfos) {
      checkIfError(costInfos.cardCost + costInfos.deliveryMethodCost);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency, mainCostValue, walletsList, costInfos]);

  useEffect(() => {
    getDeliveryAddresses().then(({ data }) => setDeliveryAddresses(data.items));
  }, []);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={onClose}
      title={t("PURCHASE_PLASTIC_CARD")}
    >
      {isSubmitting && <Preloader isStatic />}

      {step === "main" && (
        <MainPurchasePlasticStep
          values={values}
          touched={touched}
          errors={errors}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleBlur={handleBlur}
          onClose={onClose}
          addressOptions={addressOptions}
          deliveryMethodsOptions={deliveryMethodsOptions}
          cardInfo={cardInfo}
          setNewDeliveryAddresses={setNewDeliveryAddresses}
          setNewAddress={setNewAddress}
          currencies={currencies}
          costInfos={costInfos}
          selectedCurrency={selectedCurrency}
          walletsList={walletsList}
          setSelectedCurrecy={setSelectedCurrecy}
          hasError={hasError}
          cost={mainCostValue}
          currencyVariants={currencyVariants}
        />
      )}

      {step === "success" && <SuccessPurchasePlasticStep onClose={onClose} />}
    </BasicModal>
  );
};

export default observer(PurchasePlasticCryptoCardModal);
