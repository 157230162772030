import styled from "styled-components";
import { colors } from "helpers/consts";

interface MainContainerInterface {
  $isSingle?: boolean;
}
export const MainContainer = styled.section<MainContainerInterface>`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray_200};
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ $isSingle }) =>
    $isSingle && "padding: 2.4rem 2.4rem 3.2rem; margin-top: 2.4rem;"}

  ${({ $isSingle }) =>
    $isSingle
      ? `  & > *:not(:first-child) {
    margin-top: 1.6rem;
  }`
      : `  & > *:not(:first-child) {
    margin-top: 2.4rem;
  }`};

  @media (min-width: 36em) {
    border-radius: 1.2rem;
  }
`;

export const SectionContainer = styled.section`
  width: 100%;
  position: relative;
  border: 1px solid ${colors.gray_200};
  background-color: ${colors.white};

  &:not(:last-of-type) {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 36em) {
    border-radius: 1.2rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 1.6rem;

  & > *:not(:first-child) {
    margin: 1.4rem 0 0 0;
  }

  @media (min-width: 36em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.4rem;

    & > *:not(:first-child) {
      margin: 0 0 0 1.4rem;
    }
  }
`;

interface HeadingContentInterface {
  $withMargin?: boolean;
  $withButton?: boolean;
  $leftAligned?: boolean;
  $withButtonsGroup?: boolean;
}
export const HeadingContent = styled.div<HeadingContentInterface>`
  padding: ${({ $withButtonsGroup }) =>
    !$withButtonsGroup ? "2rem 2.4rem" : "2rem 1.8rem 2rem 2.4rem"};
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.gray_200};
  justify-content: ${({ $withButton }) =>
    $withButton ? "space-between" : "flex-start"};
  align-items: ${({ $leftAligned }) =>
    $leftAligned ? "flex-start" : "center"};
  ${({ $withMargin }) => $withMargin && "margin-bottom: 2.4rem;"}
  background: linear-gradient(
    90deg,
    rgba(249, 172, 70, 0) 50%,
    rgba(249, 172, 70, 0.05) 100%
  );

  & > *:not(:first-child) {
    margin: 1.6rem 0 0 0;
  }

  @media (min-width: 49em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > *:not(:first-child) {
      margin: 0 0 0 1.6rem;
    }
  }
`;

interface HeadingInfoInterface {
  $leftAligned?: boolean;
}
export const HeadingInfo = styled.div<HeadingInfoInterface>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $leftAligned }) =>
    $leftAligned ? "flex-start" : "center"};

  & > *:not(:first-child) {
    margin-top: 0.4rem;
  }

  @media (min-width: 40em) {
    align-items: flex-start;
  }
`;

interface HeadingStatusInfoInterface {
  $withExtraChip?: boolean;
}
export const HeadingStatusInfo = styled.div<HeadingStatusInfoInterface>`
  flex-wrap: nowrap;
  display: flex;
  align-items: ${({ $withExtraChip }) =>
    $withExtraChip ? "flex-start" : "center"};
  flex-direction: ${({ $withExtraChip }) =>
    $withExtraChip ? "column" : "row"};

  & > *:not(:first-child) {
    margin: ${({ $withExtraChip }) =>
      $withExtraChip ? "0.4rem 0 0 0" : "0 0 0 0.8rem"};
  }

  ${({ $withExtraChip }) =>
    $withExtraChip &&
    `
    @media (min-width: 56em) {
    flex-direction: row;
    align-items: center;

    & > *:not(:first-child) {
      margin: 0 0 0 0.8rem;
    }
  }`}
`;

export const InputsContent = styled.div`
  flex-wrap: nowrap;
  display: flex;

  & > *:not(:first-child) {
    margin-left: 1.6rem;
  }
`;

export const HeadingButtons = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  & > * {
    margin: 0.6rem;
  }

  @media (min-width: 24em) {
    align-items: center;
  }

  @media (min-width: 38em) {
    justify-content: flex-end;
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;
  justify-content: start;
  gap: 2.4rem 1.2rem;
  padding: 0 2.4rem 2.4rem;

  @media (min-width: 26em) {
    align-items: stretch;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 42em) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 52em) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 66em) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 75em) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

interface ProductContainerInterface {
  $isArchived?: boolean;
}
export const ProductContainer = styled.button<ProductContainerInterface>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 17.4rem;
  border-bottom-right-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border: solid 1px ${colors.gray_100};
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  max-width: 22rem;
  min-width: 48%;
  ${({ $isArchived }) => $isArchived && "opacity: 0.6;"}

  & > img {
    width: 100%;
  }

  &:hover,
  &:focus {
    opacity: 1;
    border: solid 1px ${colors.primary_600};
  }
  &:active {
    opacity: 0.6;
  }
`;

export const ProductInfo = styled.div`
  width: 100%;
  padding: 1.2rem 1.2rem 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

interface ProductBottomInfoInterface {
  $withTopBorder?: boolean;
}
export const ProductBottomInfo = styled.div<ProductBottomInfoInterface>`
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $withTopBorder }) =>
    $withTopBorder &&
    `border-top: solid 1px ${colors.gray_100}; padding-top: 0.8rem;`}
`;

export const ProductCost = styled.p`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0.8rem 0;
  margin: 0.8rem 0;
  border-top: solid 1px ${colors.gray_100};
  border-bottom: solid 1px ${colors.gray_100};
`;

export const ProductBalace = styled.p`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  border-bottom: solid 1px ${colors.gray_100};

  & > b {
    font-weight: 600;
    color: ${colors.gray_700};
  }
`;

export const BuyButton = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${colors.primary_600};
  margin-left: 0.7rem;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  & > svg path {
    stroke: ${colors.primary_600};
  }
`;

export const CardProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem 2rem;

  & > *:last-child {
    margin: 0 0 1.6rem 0;
  }

  & > img {
    max-width: 20rem;
    border-radius: 1.2rem;
  }

  @media (min-width: 28em) {
    & > img {
      max-width: 22rem;
    }
  }

  @media (min-width: 38em) {
    & > img {
      max-width: 24rem;
    }
  }

  @media (min-width: 48em) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    padding: 0 2.4rem 4rem;

    & > *:last-child {
      margin: 0 0 0 3rem;
    }

    & > img {
      max-width: 30rem;
    }
  }
`;

export const CardProductContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 51.2rem;

  & > *:not(:first-child) {
    margin-top: 1.6rem;
  }
`;

interface CardProductFormInterface {
  $isCrypto?: boolean;
}
export const CardProductForm = styled.form<CardProductFormInterface>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 51.2rem;

  & > *:not(:first-child) {
    margin-top: ${({ $isCrypto }) => ($isCrypto ? "0.8rem" : "1.6rem")};
  }
`;

export const InputContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const ExternalHtml = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.2rem;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }

  & a {
    color: ${colors.primary_500};
    text-decoration: underline;
  }
  & p {
    font-weight: 400;
    line-height: 1.3;
  }
`;

export const CardImageContainer = styled.div`
  position: relative;
  max-width: 100%;

  & > p {
    position: absolute;
    z-index: 4;
    text-shadow: 1px 1px 2px black;
    left: 1.6rem;
  }

  @media (min-width: 48em) {
    margin-top: 1.6rem;
  }
`;
interface CardBackInterface {
  $bgColor?: string;
}
export const CardBack = styled.div<CardBackInterface>`
  width: 49.2rem;
  max-width: 100%;
  padding-bottom: 62%;
  border-radius: 1.6rem;
  position: relative;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "#121745")};
`;

export const CardBackLine = styled.div`
  width: 100%;
  padding-bottom: 12%;
  position: absolute;
  background-color: #000000;
  top: 12%;
  left: 0;
`;
interface CardinfoInterface {
  $isSingle?: boolean;
}
export const CardInfo = styled.div<CardinfoInterface>`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  & > *:not(:first-child) {
    margin: 2rem 0 0 0;
  }

  & > img,
  & > ${CardImageContainer} {
    max-width: 22rem;
    border-radius: 1.2rem;

    & > img {
      max-width: 22rem;
      border-radius: 1.2rem;
    }
  }

  @media (min-width: 28em) {
    & > img,
    & > ${CardImageContainer} {
      max-width: 24rem;

      & > img {
        max-width: 24rem;
      }
    }
  }

  @media (min-width: 38em) {
    & > img,
    & > ${CardImageContainer} {
      max-width: 26rem;

      & > img {
        max-width: 26rem;
      }
    }
  }

  @media (min-width: 48em) {
    & > img,
    & > ${CardImageContainer} {
      max-width: 30rem;

      & > img {
        max-width: 30rem;
      }
    }
  }

  @media (min-width: 67em) {
    max-width: 100%;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    min-width: max-content;
    ${({ $isSingle }) => $isSingle && "justify-content: flex-end;"}

    & > *:not(:first-child) {
      margin: 0 0 0 3rem;
    }
  }
`;

export const WarningInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 2rem 1.2rem;
  background-color: ${colors.error_50};
  border: 2px solid ${colors.error_400};
  border-radius: 0.8rem;

  & > *:not(:first-child) {
    margin-top: 2rem;
  }
`;

export const LimitsContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;
  gap: 1.6rem;
  margin-top: 1.6rem;
  padding: 0 1.6rem;

  @media (min-width: 36em) {
    padding: 0;
  }

  @media (min-width: 62em) {
    grid-template-columns: 1fr 1fr;
    gap: 2.4rem;
    margin-top: 2.4rem;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  border: 1px solid ${colors.gray_200};
  background-color: ${colors.white};
  padding-top: 2rem;
  width: 100%;
  overflow: hidden;
  position: relative;

  & > p {
    padding: 0.6rem 2.4rem;
  }
`;

export const RowData = styled.div`
  display: flex;
  flex-direction: column;

  & > button {
    justify-content: flex-start;
    padding: 1rem 2.4rem;
  }
`;

export const CardDetailsInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
`;
