import { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import {
  ModalButton,
  CloseModalButton,
  BackModalButton,
} from "components/Buttons";
import { TextInput } from "components/Inputs";
import { AppText } from "components";
import { ModalHeading, Form, InputContainer } from "../styled";
import { usePasswordHints } from "helpers/hooks";
import { RowInfo } from "pages/LoginPage/styled";
import { colors } from "helpers/consts";

import { ReactComponent as CheckIcon } from "assets/icons/check-filled.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

interface MainChangePassStepProps {
  isSubmitting: boolean;
  values: { [field: string]: any };
  onClose: () => void;
  errors: { [field: string]: string };
  handleBlur: (e: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  handleSubmit: () => void;
  touched: { [field: string]: boolean };
  is2FAEnabled: boolean;
}

const MainChangePassStep: FC<MainChangePassStepProps> = ({
  onClose,
  isSubmitting,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  is2FAEnabled,
}) => {
  const { t } = useTranslation();

  const {
    hasValidLength,
    hasNumber,
    hasUpperCase,
    hasLowerCase,
    hasSpecialChar,
  } = usePasswordHints(values.newPassword);

  return (
    <Fade in timeout={500}>
      <Form onSubmit={handleSubmit}>
        <ModalHeading $centered $capitalized>
          {t("CHANGE_PASSWORD")}
        </ModalHeading>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onClose} />

        <InputContainer style={{ marginTop: "1rem" }}>
          <AppText>{t("CURRENT_PASSWORD")}</AppText>
          <TextInput
            type="password"
            name="oldPassword"
            placeholder={t("ENTER_CURRENT_PASSWORD")}
            value={values.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.oldPassword && Boolean(errors.oldPassword)}
            helperText={
              touched.oldPassword && errors.oldPassword && t(errors.oldPassword)
            }
          />
        </InputContainer>

        <InputContainer>
          <AppText>{t("NEW_PASSWORD")}</AppText>
          <TextInput
            type="password"
            name="newPassword"
            placeholder={t("ENTER_NEW_PASSWORD")}
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.newPassword && Boolean(errors.newPassword)}
            helperText={
              touched.newPassword && errors.newPassword && t(errors.newPassword)
            }
          />
        </InputContainer>

        <InputContainer>
          <AppText>{t("CONFIRM_PASSWORD")}</AppText>
          <TextInput
            type="password"
            name="confirmPass"
            placeholder={t("CONFIRM_PASSWORD")}
            value={values.confirmPass}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.confirmPass && Boolean(errors.confirmPass)}
            helperText={
              touched.confirmPass && errors.confirmPass && t(errors.confirmPass)
            }
          />
        </InputContainer>

        {is2FAEnabled && (
          <InputContainer>
            <AppText>{t("2FA Code")}</AppText>
            <TextInput
              placeholder={t("CODE")}
              name="oneTimePassword"
              value={values.oneTimePassword}
              onChange={handleChange}
              leftIcon={<LockIcon />}
              autoComplete="off"
            />
          </InputContainer>
        )}

        <RowInfo
          $isSuccess={touched.newPassword && !errors.newPassword}
          $noMargin
        >
          <CheckIcon />
          <AppText fontWeight={400} color={colors.gray_600}>
            {(!hasValidLength ||
              !hasNumber ||
              !hasUpperCase ||
              !hasLowerCase ||
              !hasSpecialChar) &&
              "Use min."}{" "}
            {!hasValidLength && "8 chars,"} {!hasNumber && "1 number,"}{" "}
            {!hasUpperCase && "1 uppercase,"} {!hasLowerCase && "1 lowercase,"}{" "}
            {!hasSpecialChar && "1 symbol"}
          </AppText>
        </RowInfo>

        <ModalButton
          variant="contained"
          fullWidth
          type="submit"
          style={{ marginTop: "2.8rem" }}
          disabled={isSubmitting || (is2FAEnabled && !values.oneTimePassword)}
        >
          {t("CHANGE")}
        </ModalButton>
      </Form>
    </Fade>
  );
};

export default MainChangePassStep;
